import styled from '@emotion/styled';
import _ from 'lodash';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Carousel from 'react-slick';
import { IMAGE_SIZE } from 'src/components/cards/image';
import { SHOW_COMMEMORATE } from 'src/constants';
import { APP_IMG } from 'src/constants/index';
import { ICover } from 'src/interface/section';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
const Image = dynamic(import('src/components/cards/image'));

type Props = {
  data: ICover[];
};
const SectionCover: NextPage<Props> = ({ data }) => {
  if (_.isEmpty(data)) return null;
  // console.log('SectionCover', data);
  const router = useRouter();
  const urlImage = `${APP_IMG}/uploads/images/event/`;

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <SectionWrapper id='section-cover' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
      <ShowDesktop>
        {
          <Carousel aria-hidden='true' {...settings}>
            {data
              .filter((data: ICover) => data.imagePath !== null)
              .map((item: ICover, index: number) => (
                <BannerSpecial
                  key={index}
                  className='banner-special'
                  href={!item?.url ? '#' : item.url}
                  target={!item.url ? '' : '_blank'}
                  rel='noreferrer noopenner'
                  aria-label={item?.title}
                  onClick={() =>
                    item.url &&
                    sendDataLayerGlobal({
                      type: DATALAYER_TYPE.TRACK_INDEX,
                      router: router?.pathname,
                      section: 'imagecover',
                      data: {
                        title: item.title,
                        heading: 'imagecover',
                        carousel: index + 1
                      }
                    })
                  }
                >
                  <Image image={`${urlImage}${item.imagePath}`} title={item?.alt_image ?? item?.title} external={true} size={IMAGE_SIZE.XXL_WEBP} />
                  {/* <img src={`${urlImage}${item.imagePath}`} alt={item?.title} title={item?.title} width='100%' height='100%' loading='lazy' /> */}
                </BannerSpecial>
              ))}
          </Carousel>
        }
      </ShowDesktop>
      <ShowMobile>
        {
          <Carousel aria-hidden='true' {...settings}>
            {data
              .filter((data: ICover) => data.imagePathMobile !== null)
              .map((item: ICover, index: number) => (
                <BannerSpecial
                  key={index}
                  className='banner-special'
                  href={!item?.url ? '#' : item.url}
                  target={!item.url ? '' : '_blank'}
                  rel='noreferrer noopenner'
                  aria-label={item?.title}
                  onClick={() =>
                    item.url &&
                    sendDataLayerGlobal({
                      type: DATALAYER_TYPE.TRACK_INDEX,
                      router: router?.pathname,
                      section: 'imagecover',
                      data: {
                        title: item.title,
                        heading: 'imagecover',
                        carousel: index + 1
                      }
                    })
                  }
                >
                  <Image image={`${urlImage}${item.imagePathMobile}`} title={item?.alt_image ?? item?.title} external={true} size={IMAGE_SIZE.MD_WEBP} />
                  {/* <img src={`${urlImage}${item.imagePathMobile}`} alt={item?.title} title={item?.title} width='2000px' height='560px' loading='lazy' /> */}
                </BannerSpecial>
              ))}
          </Carousel>
        }
      </ShowMobile>
    </SectionWrapper>
  );
};

const BannerSpecial = styled.a`
  img {
    display: block;
    max-width: 100%;
    width: 100%;
    /* height: 560px; */
    object-fit: cover;
    /* @media (max-width: 768px) {
      height: 360px;
  
    } */
  }
`;
const SectionWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  min-height: 365px;
  @media (max-width: 1024px) {
    min-height: 250px;
  }
  @media (max-width: 768px) {
    min-height: 220px;
  }
  @media (max-width: 690px) {
    min-height: 250px;
  }
  @media (max-width: 360px) {
    min-height: 200px;
  }

  .slick-slide {
    display: inline-grid !important;
    & > div {
      display: inline-grid;
      width: 100%;
    }
  }
`;
const ShowDesktop = styled.div`
  display: block;
  overflow-x: hidden;
  @media (max-width: 767px) {
    display: none;
  }
`;
const ShowMobile = styled.div`
  display: block;
  overflow-x: hidden;
  @media (min-width: 767px) {
    display: none;
  }
`;
export default SectionCover;
